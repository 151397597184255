import type { FunctionComponent } from 'react'

import clsx from 'clsx'
import { Children, cloneElement, useEffect, useRef, useState } from 'react'

import css from './navigation.module.scss'
import Overflow from './overflow'

interface IntersectionObserverProps {
  children: object[],
  className?: string
  role?: string
}

const IntersectionObserverWrapper: FunctionComponent<IntersectionObserverProps> = ({ children, className, role }) => {
  const navRef = useRef(null)
  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>({})
  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const updatedEntries: Record<string, boolean> = {}
    entries.forEach((entry, index) => {
      const nextEntry = entries[index + 1]
      const nextEntryIsIntersecting = nextEntry ? nextEntry.isIntersecting : true

      const targetid = entry.target.dataset.targetid
      updatedEntries[targetid] = !!entry.isIntersecting && nextEntryIsIntersecting
    })

    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries
    }))
  }
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: .9
    })

    Array.from(navRef.current.children).forEach((item) => {
      if (item.dataset.targetid) {
        observer.observe(item)
      }
    })
    return () => observer.disconnect()
  }, [])

  return (
    <ul ref={navRef}
        role={role}
        className={clsx(
          css.wrapper,
          className
        )}
    >
      {Children.map(children, (child) => {
        return cloneElement(child, {
          className: clsx(child.props.className, {
            [css.visible]: typeof window === 'undefined' || !!visibilityMap[child.props['data-targetid']],
            [css.inVisible]: typeof window !== 'undefined' && !visibilityMap[child.props['data-targetid']]
          })
        })
      })}
      <Overflow
        visibilityMap={visibilityMap}
        className={css.overflowMenu}
      >
        {children}
      </Overflow>
    </ul>
  )
}

export default IntersectionObserverWrapper
