import type { FunctionComponent, MouseEventHandler } from 'react'

import clsx from 'clsx'
import { Children, cloneElement, useMemo, useState } from 'react'

import Button from '../../molecules/button'

import css from './navigation.module.scss'
import styles from './navigation.module.scss'

interface OverflowMenuProps {
  children: object[],
  className?: string,
  visibilityMap: object
}

const OverflowMenu: FunctionComponent<OverflowMenuProps> = ({ children, className, visibilityMap }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    open ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const shouldShowMenu = useMemo(
    () => Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap]
  )

  if (!shouldShowMenu) {
    return null
  }

  const childComponents = Children.map(children, (child) => {
    if (!visibilityMap[child.props['data-targetid']]) {
      return cloneElement(child, {
        className: clsx(child.className, css.inOverflowMenu)
      })
    }
    return null
  })

  return (
    <li className={className}>
      <Button
        className={clsx(
          css.overflowButton,
          open && css.overflowButtonOpened
        )}
        iconAfter={'caret-down'}
        onClick={handleClick}
      >
        meer
      </Button>
      <ul
        className={clsx(
          open ? css.opened : css.closed,
          styles.navigationList
        )}
        onClose={handleClose}
      >
        {childComponents}
      </ul>
    </li>
  )
}

export default OverflowMenu
